import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/layout';
import Navbar from '../components/navbar';
import FreeTrial from '../components/free-trial';
import Footer from '../components/footer'

import SEO from '../components/seo';

const Post = ({data, location}) => {
  const post = data.ghostPost;

  return (
    <>
      
      <Layout>
        <SEO 
          title={post.meta_title || post.title}
          description={post.excerpt.substring(0, 150)}
          fullImageSrc={post.feature_image}
          type="article"
          pathname={location.pathname}
         />
        <Navbar />
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="flex flex-col items-center px-4 sm:px-6 lg:px-8">
              
                {post.primary_tag != null &&
                    <span className="mt-2 block text-lg text-center font-medium leading-8 tracking-tight text-highlight-900 sm:text-xl">
                     {post.primary_tag.name}
                  </span>
                }
                <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {post.title}
                </h1>
               
                <div className="flex w-1/2 justify-center">
                  <span className="mt-4 block text-base text-center leading-8 italic text-gray-600 sm:text-xl">
                  {post.excerpt?.substring(0, 200)}...
                  </span>
               </div>
               <div className="flex flex-col items-center mt-4 border-t-2 py-4 border-highlight-500">
                <div className="flex flex-row items-center">
                  <img className="w-12 h-12 rounded-full" src={post.primary_author.profile_image} />
                  <div className="ml-4 flex flex-col">
                    <span className="text-sm tracking-tight font-bold">{post.primary_author.name}</span>
                    <span className="text-xs text-gray-600">Posted on {post.published_at}</span>
                  </div>
                </div>
                  
                </div>
                <div className="flex flex-row justify-center mt-4">
                  <img class="w-full lg:w-1/2" src={post.feature_image} alt="" />
                </div>
            </div>
            <div className="mt-12 px-4 lg:px-0 w-full prose lg:prose-xl text-gray-600 mx-auto"  dangerouslySetInnerHTML={{ __html: post.html }}>
              
            </div>
        </div>
        <FreeTrial />
        <Footer />
      </Layout>
    </>
  )
}

export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      meta_title
      slug
      excerpt
      published_at (formatString: "dddd, MMMM Do YYYY")
      feature_image
      html
      primary_author {
        name
        profile_image
      }
      primary_tag {
        name
      }
    }
  }
`